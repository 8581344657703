import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_SIGNED_URL = gql`
  mutation generateSignedUrl(
    $action: String!
    $extension: String!
    $contentType: String
    $key: String!
  ) {
    generateSignedUrl(
      action: $action
      data: { extension: $extension, key: $key, contentType: $contentType }
    ) {
      signedRequest
      url
    }
  }
`;
