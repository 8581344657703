import { Layout } from 'antd';
import React from 'react';

const { Header } = Layout;

const AppHeader = (props) => {
  const { children } = props;

  return (
    <Header className="site-layout-sub-header-background app-header">
      <div className="header-wrapper header-portal-wrapper d-flex justify-between align-center fill-width">
        <div id="header-left-content" />
        <div id="header-right-content" />
      </div>
      {children}
    </Header>
  );
};
export default AppHeader;
