import { Layout } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import HotKeysModal from '../components/Hotkeys/HotKeysModal';
import './App.css';
import AppHeader from './components/header/AppHeader';
import Sidebar from './components/sidebar/Sidebar';

const { Content, Footer } = Layout;
const App = () => (
  <>
    <Layout hasSider className="main-layout">
      <Sidebar />
      <Layout className="site-layout">
        <AppHeader />
        <Content>
          <Outlet />
        </Content>
        <Footer />
      </Layout>
    </Layout>
    <HotKeysModal />
  </>
);

export default App;
